import { useSelector } from 'react-redux';
import { gameNameSelector, gameUidSelector, gameIdSelector, regionSelector } from '../../../selectors/gameContext';
import { countryCodeSelector, gameLabelSelector, gameProviderNameSelector } from '../../../selectors/game-window';
import { useCallback } from 'preact/hooks';
import { logTrackingEvent, getAndroidAppVersion } from '../../helpers/user-tracking.helpers';
import { getAmplitudeKey, Undef } from './types';
import { AmplVal } from './amplitude-gtm.mapper';
import { getSitePlatformAmplitude, getSiteVersionAmplitude } from '../../helpers/app-platform';

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const useLogTracking = () => {
    const gameId: Undef<string> = useSelector(gameIdSelector);
    const gameName: Undef<string> = useSelector(gameNameSelector);
    const gameProviderName: Undef<string> = useSelector(gameProviderNameSelector);
    const region: Undef<string> = useSelector(regionSelector);
    const countryCode: Undef<string> = useSelector(countryCodeSelector);
    const favouriteGames: Undef<string>[] = useSelector(state => state?.['userContextReducer']?.userContextData?.favouriteGames);
    const gameLabel: Undef<string> = useSelector(gameLabelSelector);
    const gameUId: Undef<string> = useSelector(gameUidSelector);
    const loginStatus: Undef<string> = useSelector(
        state => state?.['userContextReducer']?.userContextData?.accountSettings?.loggedIn
    );
    const product: Undef<string> = useSelector(
        state => state?.['geocomply']?.location?.details?.product
    );
    const fundsData: Undef<string> = useSelector(
        state => state?.['balance']?.loadingGetBalance ? 'Loading...' : state?.['balance']?.playableBalance
    );

    const getProductName = () => {
        if (product) {
            return (product === 'CASINO' ? 'casino_standalone_lobby' : 'sportsbook');
        }
        return ('-');
    }

    const isJackpot = gameLabel ? (gameLabel === 'Jackpot') : '-';

    const logTrackingComponent = useCallback(
        (payload: AmplVal) => {
            logTrackingEvent({
                [getAmplitudeKey('BALANCE')]: fundsData,
                [getAmplitudeKey('GAME_ID')]: gameUId,
                [getAmplitudeKey('GAME_NAME')]: gameName,
                [getAmplitudeKey('PAGE_NAME')]: 'game_window',
                [getAmplitudeKey('GAME_PROVIDER')]: gameProviderName,
                [getAmplitudeKey('JURISDICTION')]: region ? region.toUpperCase() : '-',
                [getAmplitudeKey('NATIONAL_JURISDICTION')]: countryCode === 'CA' ? 'canada' : 'usa',
                [getAmplitudeKey('APP_VERSION')]: getAndroidAppVersion(),
                [getAmplitudeKey('FULL_URL')]: window.location.href,
                [getAmplitudeKey('PRODUCT')]: getProductName(),
                [getAmplitudeKey('SITE_PLATFORM')]: getSitePlatformAmplitude(),
                [getAmplitudeKey('SITE_VERSION')]: getSiteVersionAmplitude(),
                [getAmplitudeKey('PAGE_PATH')]: window.location.pathname,
                [getAmplitudeKey('IS_FAVORITE')]: favouriteGames ? favouriteGames.includes(gameUId) : '-',
                [getAmplitudeKey('LAUNCHED_FROM')]: 'web_launcher',
                [getAmplitudeKey('LOGIN_STATUS')]: loginStatus ? 'logged_in' : 'logged_out',
                [getAmplitudeKey('IS_JACKPOT')]: isJackpot,
                ...payload
            });
        },
        [gameId, gameName, gameProviderName]
    );

    return { logTrackingComponent };
};
