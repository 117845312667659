import { h } from 'preact';
import config from '../../../../../config/config';
import { MESSAGES, RM_USER_ELIGIBILITY_ERRORS, THEMES } from '../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'preact/hooks';
import { getCustomerPackage } from '../../../../redux/actions/customer-data';
import { showGeneralError } from '../../../../redux/actions/app-error';
import { loginRedirect } from '../../../../common/helpers/loginRedirect';
import { getQueryParameter } from '../../../../common/helpers/queryparams';
import { isIosWrapper, isRunningInsideWrapper, sendWrapperEvent } from '../../../../common/wrapper-bridge-mobile';
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper';
import { ddActionRmGameMount } from '../../../../common/datadog/reward-machine/rm-game-mount';
import { ddActionRmSendPlayerData } from '../../../../common/datadog/reward-machine/rm-send-player-data';
import { ddActionRmMessageReceivedInit } from '../../../../common/datadog/reward-machine/rm-message-received-init';
import { ddActionRmMessageReceivedPlayerDataRequest } from '../../../../common/datadog/reward-machine/rm-message-received-player-data';
import { ddActionRmMessageReceivedLoginAction } from '../../../../common/datadog/reward-machine/rm-message-received-login-action';
import { ddActionRmMessageReceivedRedirectAction } from '../../../../common/datadog/reward-machine/rm-message-received-redirect-action';
import { ddActionRmTrackingActions } from '../../../../common/datadog/reward-machine/rm-tracking-actions';
import { ddActionRmMessageReceivedDefault } from '../../../../common/datadog/reward-machine/rm-message-received-default';
import { ddActionRmWebRedirectParentWindow } from '../../../../common/datadog/reward-machine/rm-web-redirect-parent-window';
import { ddActionRmWebRedirectSameWindow } from '../../../../common/datadog/reward-machine/rm-web-redirect-same-window';
import { ddActionRmWebRedirectPageAndroid } from '../../../../common/datadog/reward-machine/rm-web-redirect-page-android';
import { ddActionRmWebRedirectPageIos } from '../../../../common/datadog/reward-machine/rm-web-redirect-page-ios';
import { ddActionRmWebRedirectPageOther } from '../../../../common/datadog/reward-machine/rm-web-redirect-page-other';
import { DD_USE_CASES } from '../../../../common/datadog/enums';
import { types } from '../../../../redux/types';

//todo move to constants
const INIT_INSTANCE = 'INIT_INSTANCE';
const PLAYER_DATA_ACTION = 'playerData';
const LOGIN_ACTION = 'login';
const REDIRECT_ACTION = 'redirect';
const TRACKING_ACTION = 'tracking';

const HOME = 'home';
const EXIT = 'exit';
const INFO = 'termsandconditions';
const CUSTOM_REDIRECT_TYPE = 'custom';
const redirects = {
    home: '/',
    exit: '/reward-machine-exit-screen',
    termsandconditions: '/reward-machine-info',
    custom: 'custom'
};

const PAGES = {
    bonus_draw: '/bonusdraw/exit'
};
const REFS = {
    game: 'game'
};

/**
 * More details can be found here: https://fanduel.atlassian.net/wiki/spaces/CAS/pages/306183536940/Gaming+Realms
 */
const sendPostMessage = (iframeRef, action, payload, igProductURL) => {
    iframeRef?.postMessage({ action, payload }, igProductURL);
};

const webHandler = (redirectType, redirectUrl) => {
    if (window.opener && !isIosWrapper()) {
        window.close();
        ddRumAction(ddActionRmWebRedirectParentWindow({ redirectType }, DD_USE_CASES.RM_GAME));
        let payload;

        if (redirectType === CUSTOM_REDIRECT_TYPE) {
            payload = decodeURI(redirectUrl);
        } else {
            payload = redirects[redirectType];
        }

        window?.opener?.postMessage?.(
            {
                type: MESSAGES.INCENTIVE_GAME_REDIRECT,
                payload
            },
            config.casinoWebUrl
        );
    } else {
        ddRumAction(ddActionRmWebRedirectSameWindow({ redirectType }, DD_USE_CASES.RM_GAME));

        if (redirectType === CUSTOM_REDIRECT_TYPE) {
            window.location = decodeURI(redirectUrl);
        } else {
            window.location = config.casinoWebUrl + redirects[redirectType];
        }
    }
};

const IncentiveGames = () => {
    const dispatch = useDispatch();
    const customerData = useSelector(state => state?.customerData?.data);
    const [isEligible, setIsEligible] = useState(undefined);
    const [receivedPlayerDataEvent, setReceivedPlayerDataEvent] = useState(false);
    const iFrameID = 'daily-game';

    const gameURL = useMemo(() => {
        const timestamp = Math.floor(Date.now() / 1000);

        let theme = getQueryParameter('theme')?.toLowerCase();
        theme = Object.values(THEMES).includes(theme) ? theme : THEMES.DEFAULT;
        const page = PAGES[getQueryParameter('page')?.toLowerCase()] || '';

        return `${config?.incentiveGamesApi?.baseUrl}${page}?timestamp=${timestamp}&theme=${theme}`;
    }, []);

    const exitScreenRedirect = ref => {
        if (ref && ref === REFS.game) {
            sendWrapperEvent('REWARD_MACHINE_SHOW', event?.data);
            let url = new URL(location.href);
            url.searchParams.delete('ref');
            url.searchParams.delete('page');
            location.href = url.href;
        } else if (ref && ref !== REFS.game) {
            webHandler(CUSTOM_REDIRECT_TYPE, ref);
        } else {
            webHandler(EXIT);
        }
    };

    const redirectPage = (redirectType, ref) => {
        switch (redirectType) {
            case HOME: {
                if (isRunningInsideWrapper()) {
                    ddRumAction(ddActionRmWebRedirectPageAndroid(DD_USE_CASES.RM_GAME));
                    sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
                } else if (isIosWrapper()) {
                    ddRumAction(ddActionRmWebRedirectPageIos(DD_USE_CASES.RM_GAME));
                    sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE);
                } else {
                    webHandler(HOME);
                }
                break;
            }
            case EXIT: {
                //todo: Handling for duplicate pages since we redirect users in the same window as launcher
                exitScreenRedirect(ref);
                break;
            }
            case INFO: {
                // todo: send message on ios to redirect to native info page, if web is not used
                webHandler(INFO);
                break;
            }
            default: {
                ddRumAction(ddActionRmWebRedirectPageOther({ redirectType }, DD_USE_CASES.RM_GAME));
                console.log('redirect other');
            }
        }
    };

    const messageHandler = () => {
        window.addEventListener(
            'message',
            event => {
                const action = event.data?.action;
                const payload = event.data?.payload;

                // Whatever the event, if there is any update to entry it should be sent to lobby
                if (payload?.entry) {
                    window?.opener?.postMessage?.(
                        {
                            type: MESSAGES.INCENTIVE_GAME_UPDATE,
                            payload: event.data.payload.entry
                        },
                        config.casinoWebUrl
                    );
                }

                //todo add an event.origin check
                switch (action) {
                    case INIT_INSTANCE: {
                        ddRumAction(ddActionRmMessageReceivedInit({ action, payload }, DD_USE_CASES.RM_GAME));
                        break;
                    }
                    case PLAYER_DATA_ACTION: {
                        ddRumAction(ddActionRmMessageReceivedPlayerDataRequest({ action, payload }, DD_USE_CASES.RM_GAME));
                        setReceivedPlayerDataEvent(true);
                        break;
                    }
                    case LOGIN_ACTION: {
                        //todo Implement with dependency injection
                        if (isIosWrapper()) {
                            ddRumAction(
                                ddActionRmMessageReceivedLoginAction({ ios: true, action, payload }, DD_USE_CASES.RM_GAME)
                            );
                            sendWrapperEvent(MESSAGES.LOGIN_REDIRECT, event?.data);
                        } else {
                            ddRumAction(ddActionRmMessageReceivedLoginAction({ action, payload }, DD_USE_CASES.RM_GAME));
                            loginRedirect();
                        }
                        break;
                    }
                    case REDIRECT_ACTION: {
                        let ref = getQueryParameter('ref')?.toLowerCase() || '';
                        ddRumAction(ddActionRmMessageReceivedRedirectAction({ action, payload }, DD_USE_CASES.RM_GAME));
                        redirectPage(payload.redirectType, ref);
                        break;
                    }
                    case TRACKING_ACTION: {
                        ddRumAction(
                            ddActionRmTrackingActions({ event: payload?.type, action, payload }, DD_USE_CASES.RM_GAME)
                        );
                        window?.opener?.postMessage?.(
                            {
                                type: MESSAGES.TRACKING,
                                payload
                            },
                            config.casinoWebUrl
                        );
                        if (payload?.event === 'view_bonusdraw') {
                            sendWrapperEvent('BONUS_DRAW_SHOW', event?.data);
                        } else if (payload?.event === 'exit_bonusdraw') {
                            sendWrapperEvent('REWARD_MACHINE_SHOW', event?.data);
                        }
                        break;
                    }
                    default: {
                        ddRumAction(ddActionRmMessageReceivedDefault({ action, payload }, DD_USE_CASES.RM_GAME));
                    }
                }
            },
            false
        );
    };

    useEffect(() => {
        messageHandler();
        dispatch({ type: types.SET_IS_REWARDS_MACHINE });
        dispatch(getCustomerPackage());
    }, []);

    useEffect(() => {
        if (customerData) {
            setIsEligible(customerData?.isEligible);
            ddRumAction(ddActionRmGameMount({gameURL}, DD_USE_CASES.RM_GAME));
        }
    }, [customerData]);

    useEffect(() => {
        if (customerData && receivedPlayerDataEvent) {
            ddRumAction(
                ddActionRmSendPlayerData(
                    {
                        customerData,
                        gameURL
                    },
                    DD_USE_CASES.RM_GAME
                )
            );
            let iframeRef = document.getElementById(iFrameID)?.contentWindow;
            sendPostMessage(iframeRef, PLAYER_DATA_ACTION, {token: customerData?.payload}, gameURL);
        }
    }, [customerData, receivedPlayerDataEvent]);

    // todo: uncoment when the show_header functionality is added to android wrapper
    // sendWrapperEvent(MESSAGES.HIDE_HEADER, '')

    if (isEligible === false) {
        const errMsg = RM_USER_ELIGIBILITY_ERRORS.USER_NOT_ELIGIBLE;
        dispatch(showGeneralError(errMsg));
        return <div>{errMsg}</div>;
    }
    if (isEligible === null) {
        const errMsg = RM_USER_ELIGIBILITY_ERRORS.GENERAL_ERROR;
        dispatch(showGeneralError(errMsg));
        return <div>{errMsg}</div>;
    }

    return (
        <div>
            <iframe
                src={gameURL}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    bottom: 0,
                    top: 0,
                    margin: 'auto',
                    border: 'none'
                }}
                title={name || 'Incentive Games'}
                id={iFrameID}
            />
        </div>
    );
};

export default IncentiveGames;
