import { useCallback, useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { MESSAGES } from '../../../../common/constants';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../../common/wrapper-bridge-mobile';
import { ProxyMessage } from '../types';
import { useGameClientListener } from './useGameClientListener';

/**
 * The initiator param should be true only from ONE component which is always rendered in the launcher.
 * Any other component should pass nothing(false), otherwise it will cause multiple event listeners to be added.
 * @param initiator
 */
export const useGameClientProxy = (initiator = false) => {
    if (initiator) {
        useGameClientListener();
    }


    const executeEvent = useCallback((event: ProxyMessage) => {
        const gameClientFrame = document.querySelector<HTMLIFrameElement>('#casino-game-client');
        gameClientFrame?.contentWindow?.postMessage(event, '*');
    }, []);
    const balance = useSelector(state => state?.['balance']?.playableBalance);
    const reloadBalance = useCallback(
        (payload: string) =>
            executeEvent({
                type: 'GL/RELOAD_BALANCE',
                payload
            }),
        [executeEvent]
    );
    useEffect(() => {
        reloadBalance(balance)
    }, [reloadBalance, balance]);

    const togglePaytable = useCallback(
        () =>
            executeEvent({
                type: 'GL/TOGGLE_PAYTABLE'
            }),
        [executeEvent]
    );

    const toggleAudio = useCallback(
        (value: boolean) => {
            executeEvent({
                type: 'GL/TOGGLE_AUDIO',
                payload: value
            });
        },
        [executeEvent]
    );

    const autoSpinStop = useCallback(
        () => {
            executeEvent({
                type: 'GL/AUTO_SPIN_STOP'
            });
        },
        [executeEvent]
    );

    const autoSpinResume = useCallback(
        () => {
            executeEvent({
                type: 'GL/AUTO_SPIN_RESUME'
            });
        },
        [executeEvent]
    );

    const exitGame = useCallback((redirectUrl: string) => {
        if (isRunningInsideWrapper()) {
            sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, redirectUrl || '');
        } else {
            window.close();
        }
    }, []);

    const toggleHelp = useCallback(
        () =>
            executeEvent({
                type: 'GL/TOGGLE_HELP'
            }),
        [executeEvent]
    );

    const dismissErrorMessage = useCallback(() => {
        executeEvent({
            type: 'GL/DISMISS_ERROR'
        });
    }, [executeEvent]);
    
    const chooseDialog = useCallback((payload: number) => {
        executeEvent({
            type: 'GL/FREE_SPINS_DIALOG',
            payload
        });
    }, [executeEvent]);

    return {
        exitGame,
        reloadBalance,
        toggleAudio,
        togglePaytable,
        toggleHelp,
        dismissErrorMessage,
        chooseDialog,
        autoSpinStop,
        autoSpinResume,
    };
};
