import { types } from '../../types';

export const initialState = {
    loadingGetCurrentSessionDetails: false,
    loadingGetUserDetails: false,
    loadingGetServerTime: false,
    sessionId: '',
    sessionStartTime: '',
    serverTime: '',
    userId: '',
    timezone: '',
    errorGetCurrentSessionDetails: null,
    errorGetUserDetails: null,
    errorGetServerTime: null,
    mfa: null
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CURRENT_SESSION_DETAILS_REQUEST:
            return {
                ...state,
                loadingGetCurrentSessionDetails: true
            };
        case types.GET_CURRENT_SESSION_DETAILS_RECEIVED:
            return {
                ...state,
                sessionStartTime: action.payload?.sessionStartTime,
                loadingGetCurrentSessionDetails: false,
                mfa: action.payload?.mfa
            };
        case types.GET_CURRENT_SESSION_DETAILS_FAILED:
            return {
                ...state,
                errorGetCurrentSessionDetails: action.payload,
                loadingGetCurrentSessionDetails: false
            };
        case types.GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                loadingGetUserDetails: true
            };
        case types.GET_USER_DETAILS_RECEIVED:
            return {
                ...state,
                userId: action.payload?.userId,
                sessionId: action.payload?.sessionId,
                loadingGetUserDetails: false
            };
        case types.GET_USER_DETAILS_FAILED:
            return {
                ...state,
                errorGetUserDetails: action.payload,
                loadingGetUserDetails: false
            };
        case types.GET_CURRENT_SERVER_TIME_REQUEST:
            return {
                ...state,
                loadingGetServerTime: true
            };
        case types.GET_CURRENT_SERVER_TIME_RECEIVED:
            return {
                ...state,
                timezone: action.payload?.timezone,
                serverTime: action.payload?.datetime,
                loadingGetServerTime: false
            };
        case types.GET_CURRENT_SERVER_TIME_FAILED:
            return {
                ...state,
                errorGetServerTime: action.payload,
                loadingGetServerTime: false
            };
        default: {
            return state;
        }
    }
};
export default sessionReducer;
