import { useMemo } from 'preact/hooks';
import GameLauncherIncentiveGames from './IncentiveGames/Launcher';
import GameClient from './GameClient/GameClient';
import { RGIS } from '../../common/constants';

type LauncherMapper = {
    [rgi in keyof typeof RGIS[number]]: any;
};
interface GameLauncherProps {
    rgi: keyof typeof RGIS;
    [key: string]: any;
}

export const GameLauncher = ({ rgi, ...props }: GameLauncherProps) => {
    const launcherMapper: LauncherMapper = useMemo(() => ({
        [RGIS.GAN]: GameClient,
        [RGIS.INCENTIVE_GAMES]: GameLauncherIncentiveGames,
        [RGIS.FGP]: GameClient
    }), []);

    const Launcher = useMemo(
        () => (Object.keys(launcherMapper).includes(rgi) ? launcherMapper[rgi] : GameClient),
        [launcherMapper, rgi]
    );

    return <Launcher {...props} />
};
