import { h } from 'preact';

import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

// Pages - Code-splitting is automated for `routes` directory
import Home, { pathName as rootPath } from '../routes/Home/Home';
import DownloadPlugin from '../routes/DownloadPlugin/DownloadPlugin';

import useDatadog from '../common/hooks/use-datadog';
import loadDependencies from '../dependency-injection/load-dependencies';
import { CoreModalPresenterProvider } from '@fanduel/core-modal-presenter';

import store from '../redux/store';

export const pathName = '/plugin';

const ShortLinkHome = () => {
    const { gameUid } = useParams();

    return <Home gameUid={gameUid} />;
};

const App = () => {
    loadDependencies();
    useDatadog();  

    return (
        <ReduxProvider store={store}>
            <CoreModalPresenterProvider>
            <Router>
                <Switch>
                    <Route exact path={rootPath} component={Home} />
                    <Route path={pathName} component={DownloadPlugin} />
                    <Route path="/:gameUid" component={ShortLinkHome} />
                </Switch>
            </Router>
            </CoreModalPresenterProvider>
        </ReduxProvider>
    );
};

export default App;
