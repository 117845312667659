import { removeAWCookies } from './cookies'
import { checkValidSession } from '../../unified-modules/account-library'

const isLoggedIn = async () => {
    try {
        const validSession = await checkValidSession() 

        if (validSession){
            return true
        }
        removeAWCookies()
        return false

    } catch (error){
        console.log(error)
    }
}

export default isLoggedIn
