import { GEOCOMPLY_LICENSE_RECEIVED } from './geo-comply/license/actions/license-received'
import moment from 'moment'
import { GEOCOMPLY_LOCATION_VERIFIED } from './geo-comply/location/actions/location-verified'
import { getSessionId } from '../common/helpers/cookies'
import { GEOCOMPLY_PLC_LOCATION_VALIDATED } from './geo-plc/actions/location-validated'
import { getLocationFetchBufferTime } from '../common/helpers/plc-helper'
import { LOCAL_STORAGE_KEYS } from '../common/constants'

// Pass in Redux store's state to save it to the user's browser local storage
export const saveStateMiddleware = ({ getState }) => {
    return next => action => {
        const result = next(action)

        if ([GEOCOMPLY_LICENSE_RECEIVED].includes(result.type)) {
            const license = getState()?.geocomply?.license

            license && localStorage.setItem(LOCAL_STORAGE_KEYS.REDUX_STORE_LICENSE_KEY, JSON.stringify(license))
        }

        if ([GEOCOMPLY_LOCATION_VERIFIED, GEOCOMPLY_PLC_LOCATION_VALIDATED].includes(result.type)) {
            const location = getState()?.geocomply?.location

            location &&
                location.verified &&
                !location.error &&
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.REDUX_STORE_LOCATION_KEY,
                    JSON.stringify({
                        location,
                        plc: getState()?.plc
                    })
                )
        }
        return result
    }
}

export const hydrateStore = () => {
    try {
        const result = {}

        const cachedLicense = getLicense()

        if (cachedLicense) {
            result.geocomply = { license: cachedLicense }
        }
        const cachedLocation = getLocation();

        if (cachedLocation) {
            result.geocomply = {
                ...result.geocomply,
                location: cachedLocation.location
            }
            result.plc = cachedLocation.plc
        }

        return result
    } catch (error) {
        return undefined
    }
}

function getLicense() {
    const license = getStoreItem(LOCAL_STORAGE_KEYS.REDUX_STORE_LICENSE_KEY)

    if (license) {
        try {
            const expires = moment.utc(license.expiry_date)

            if (moment.utc().isBefore(expires)) {
                console.warn('USING CACHED LICENSE')
                // licence havn't expired
                return license
            }
            // new licence will be retreived during normal game launch flow
            console.warn('CACHED LICENSE EXPIRED')
            localStorage.removeItem(LOCAL_STORAGE_KEYS.REDUX_STORE_LICENSE_KEY)
        } catch {
            return
        }
    }
}

function getLocation() {
    const storedData = getStoreItem(LOCAL_STORAGE_KEYS.REDUX_STORE_LOCATION_KEY)
    if (storedData) {
        try {
            const sessionId = +getSessionId()
            const cachedLocation = storedData.location
            let expires = moment.utc(cachedLocation.details.expires)
            const utcNow = moment.utc()

            const bufferTime = getLocationFetchBufferTime(cachedLocation.details.geolocate_in)
            expires = expires.add(-bufferTime, 'seconds')

            if (
                cachedLocation.verified &&
                sessionId === cachedLocation?.details?.session_id &&
                utcNow.isBefore(expires)
            ) {
                console.warn('USING CACHED LOCATION')
                // location havn't expired && has same session
                return storedData
            }
            // location will be retreived during normal game launch flow
            console.warn('CACHED LOCATION EXPIRED')
            localStorage.removeItem(LOCAL_STORAGE_KEYS.REDUX_STORE_LOCATION_KEY)
        } catch {
            return
        }
    }
}

function getStoreItem(key) {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) {
        return undefined
    }

    try {
        return JSON.parse(serializedState)
    } catch {
        return undefined
    }
}
