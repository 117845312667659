import { h, Fragment } from 'preact';
import { useMemo } from 'preact/hooks';

export const Message = ({
    action,
    top,
    left,
    right,
    bottom,
    actionDismiss
}: {
    action?: () => void;
    actionDismiss?: () => void;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}) => {
    // add dynamic title, caption when new features incoming
    const position = useMemo(() => {
        if (top) {
            if (left) {
                return { left: `${left - 7}px`, top: `${top}px` };
            }
            return { right: `${right - 7}px`, top: `${top}px` };
        }
        if (left) {
            return { left: `${left - 7}px`, bottom: `${bottom}px`, alignItems: 'flex-end' };
        }
        return { right: `${right - 7}px`, bottom: `${bottom}px`, alignItems: 'flex-end' };
    }, [right, left, bottom, top]);

    return (
        <div className={`message-container`} onClick={e => e.stopPropagation()} style={{ ...position }}>
            {(top || bottom) && left && (
                <>
                    <div className="dot" />
                    <div className="line" />
                </>
            )}
            <div className="box">
                <div className="top">
                    <div className="badge" />
                    <div className="close" onClick={actionDismiss} />
                </div>
                <div className="information">
                    <div className="title">Deposit while you play</div>
                    <div className="caption">Add funds using your dashboard</div>
                </div>
                <div className="button" onClick={action} />
            </div>
            {(top || bottom) && right && (
                <>
                    <div className="line" />
                    <div className="dot" />
                </>
            )}
        </div>
    );
};
