import { getUserDataUM, getSessionUM } from '../../unified-modules/account-library';

export const getSessionId = async () => {
    const session = await getSessionUM(true);
    return session ? session.sessionId : null
};

export const getUserId = async () => {
    const userData = await getUserDataUM();
    return userData ? userData.userId : null
};
