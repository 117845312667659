import { checkValidSession, clearSession, getSessionUM } from '../../../unified-modules/account-library';
import { AW_COOKIE, MESSAGES } from '../../constants';
import { removeAWCookies } from '../../helpers/cookies';
import { loginRedirect } from '../../helpers/loginRedirect';
import {
    sendIosSessionExpiredMessage,
    isRunningInsideV3Wrapper,
    isRunningInsideWrapper,
    sendWrapperEvent,
    sendIosTokenRefreshMessage
} from '../../wrapper-bridge-mobile';

export const sessionExpirationRequestInterceptor = (axiosInstance) => {
    const interceptor = axiosInstance.interceptors.response.use(
        requestResponse => requestResponse,
        async requestError => {
            const status = requestError?.response?.status;
            if (status !== 401) {
                return Promise.reject(requestError);
            }
    
            const validSession = await checkValidSession();
            axiosInstance.interceptors.response.eject(interceptor);

            if (validSession) {
                const session = await getSessionUM(true);

                if (session){
                    axiosInstance.defaults.headers[AW_COOKIE.AUTH_TOKEN] = session.token;
                    requestError.response.config.headers[AW_COOKIE.AUTH_TOKEN] = session.token;

                    sendIosTokenRefreshMessage({
                        authToken: session.token,
                        loginToken: session.token
                    });

                    return axiosInstance(requestError.response.config);
                }  
            }

            if (isRunningInsideWrapper() || isRunningInsideV3Wrapper()) {
                removeAWCookies();
                clearSession();
                sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
                sendIosSessionExpiredMessage();
            } else {
                const hadActiveSession = !!sessionStorage.getItem('hadActiveSession')
                if (hadActiveSession){
                    loginRedirect();
                }
               
            }
            
            // Re-inject the response interceptor
            sessionExpirationRequestInterceptor(axiosInstance);
        })
}
