import { removeAWCookies } from './cookies';
import { clearSession } from '../../unified-modules/account-library';

const loginRedirect = () => {
    removeAWCookies()
    clearSession();

    //we use setTimeout because refreshing the page immediately cancels the execution of the clearSession method. 
    // this is a known issue, and a fix is planned for an upcoming update as the bug is currently being tracked
    setTimeout(() => {
        window.location.reload()
    }, 5000)
}

export { loginRedirect }
