import { types } from '../../types';

export type GameContextPayload = {
    rgi?: string;
    brand?: string;
    gameName?: string;
    gameId?: string;
    provider?: string;
    providerRef?: string;
    ganId?: string;
    region?: string;
    overallStatus?: string;
    gameUid?: string;
    gameLoaded?: boolean;
    nativeId?: string;
    directLaunchParams?: Record<string, unknown>;
};

export const setGameContext = (payload: GameContextPayload) => ({
    type: types.SET_GAME_CONTEXT,
    payload
});

