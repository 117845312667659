import axiosAWInstance from '../../common/axios/AWAxios'
import { commonHeadersAW } from '../commonHeaders'

const fetchRealityCheck = async userId => {
    const response = await axiosAWInstance.get(`users/${userId}/reality_check`, {
        headers: commonHeadersAW
    })
    const { data } = response

    return {
        playedTime: parseInt(data['session_time_minutes']),
        currentTimer: parseInt(data['session_time_seconds'] % (data['reality_check_interval'] * 60)),
        profitAndLoss: data['amount_wagered'],
        interval: parseInt(data['reality_check_interval'])
    }
}

export default fetchRealityCheck
