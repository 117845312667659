import { Account } from '@fanduel/account';
import { getAWCookies } from '../common/helpers/cookies';
import { initializeUnifiedModules } from './unified-modules';
import { loginRedirect } from '../common/helpers/loginRedirect';

export const initializeAccountUM = () => {
    return Account.initialize()
};

let initialize: any = null;
let isInitializingPromise: Promise<any> | null = null;

export const initializeUM = async () => {
    if (initialize) {
        return Promise.resolve(initialize);
    }

    if (isInitializingPromise) {
        await isInitializingPromise;
        return initialize;
    }

    isInitializingPromise = (async () => {
        try {
            initialize = await initializeUnifiedModules();
            return initialize;
        } finally {
            isInitializingPromise = null;
        }
    })();

    return isInitializingPromise;
};

export const subscribeSession = async () => {
    return await Account.subscribeSession((session) => {
        const hadActiveSession = !!sessionStorage.getItem('hadActiveSession')

        if (!session && hadActiveSession) {
            sessionStorage.removeItem('hadActiveSession')
            loginRedirect();
        }
    })
};

export const getSessionUM = async (useSilent?: boolean) => {
    try {
        await initializeUM()
        if (useSilent){
            return await Account.getSessionAsync('Silent')
        }
        return Account.getSessionAsync('Normal')
    } catch (err){
        console.log(err)
    }
};

export const getUserDataUM = async () => {
    try {
        await initializeUM()
        const { loginToken } = getAWCookies();
        return await Account.getUserAsync({loginToken});
    } catch (error) {
        console.error(error)
        return null
    }
};

export const checkValidSession = async () => {
    await initializeUM();
    return await Account.checkValidSession();
};

export const clearSession = () => {
    return Account.clearSession();
};

export const keepAlive = () => {
    return Account.keepAlive();
};

export const keepAliveFlow = async () => {
    const sessionUM = await getSessionUM();

    if (!sessionUM) {
        loginRedirect()
        return;
        }
          try {
            keepAlive();
          } catch (error) {
            console.log(error)
          }
};

export const getPriorSession = () => {
    return Account.getPriorSession();
};
