import { Brand } from '@fanduel/core-config-contract';
import { BRAND, COUNTRY, COUNTRY_ABBREVIATION, ENVIRONMENT } from '../../redux/types/unified-modules.types';
import config from '../../../config/config';

export const getBrand = (brand: string): Brand => {
    if (brand === BRAND.MOHEGANSUN) {
        return Brand.MoheganSun;
    }
    return Brand.FanDuel;
};

export const getCountry = (country: string) => {
    if (country === COUNTRY.CANADA) {
        return COUNTRY_ABBREVIATION.CANADA;
    }
    return COUNTRY_ABBREVIATION.USA;
};

export const getEnvironment = () => {
    const env = config.env;
    return env === ENVIRONMENT.PROD ? ENVIRONMENT.PROD 
        : process.env.COUNTRY === COUNTRY.CANADA ? ENVIRONMENT.QA
            : ENVIRONMENT.CERT;
};

