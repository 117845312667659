import { useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { inGameErrorSelector } from '../../../../../selectors/game-window';

export const useProviderErrorsTrigger = ({ setQueue, addToQueue }) => {
    const pushedMessage = useSelector(inGameErrorSelector);

    const clearQueue = () => {
        setQueue(prev => {
            const errorIndex = prev.findIndex(item => item.type === 'provider_error');

            return errorIndex === -1 ? prev : prev.splice(errorIndex, 1);
        });
    }

    useEffect(() => {
        if (pushedMessage && !!pushedMessage?.errorMessage) {
            clearQueue();
            addToQueue({ ...pushedMessage, type: 'provider_error' });
        } else {
            clearQueue();
        }
    }, [pushedMessage]);
}
